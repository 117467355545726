import { Box, Flex, Grid, GridItem, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import CirculatingSupply from '../mainPage/circulatingSupply/CirculatingSupply';
import CurrentPrice from '../mainPage/currentPrice/currentPrice';
import LineChart from '../mainPage/shidoPrice/LineChart';
import BarChart from '../mainPage/shidoVolume/BarChart';
import SpeedoMeter from '../mainPage/speedoMeter/SpeedoMeter';
import Statistics from '../mainPage/stats/statistics';
import TotalVolume from '../mainPage/totalVolume/TotalVolume';
import { gsap } from 'gsap';
import { Content_BlockTL } from '../../assets/animation/Content_Block';
import { PressureInfo } from '../mainPage/totalVolume/PressureInfo';
import configureAxios from '../../AxiosClient';

function Content() {
  const Content_BlockRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      Content_BlockTL(Content_BlockRef.current);
    }, Content_BlockRef);

    return () => ctx.revert();
  }, [Content_BlockRef]);

  const [buyingPressure, setBuyingPressure] = useState<number>(0);
  const [sellingPressure, setSellingPressure] = useState<number>(0);
  const axiosInstance = configureAxios();
  const toast = useToast();

  // const fetchPressureData = async () => {
  //   try {
  //     const [buyingResponse, sellingResponse] = await Promise.all([
  //       axiosInstance.get('http://localhost:8000/api/token/buying-pressure/'),
  //       axiosInstance.get('http://localhost:8000/api/token/selling-pressure/'),
  //     ]);

  //     if (buyingResponse.status === 200) {
  //       setBuyingPressure(Number(buyingResponse.data.currentValue) || 0);
  //     }

  //     if (sellingResponse.status === 200) {
  //       setSellingPressure(Number(sellingResponse.data.currentValue) || 0);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     toast({
  //       title: 'There was an error fetching pressure data',
  //       status: 'error',
  //       isClosable: true,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   fetchPressureData();
  // }, []);

  return (
    <>
      <Grid
        className="content-block"
        templateRows="repeat(4, 1fr)"
        templateColumns={['repeat(5, 1fr)', 'repeat(5, 1fr)', 'repeat(4, 1fr)', 'repeat(5, 1fr)', 'repeat(5, 1fr)']}
        gap={['40px', '40px', 4, 4, 4]}
        mt="32px"
        height={'100vh'}
        ref={Content_BlockRef}
      >
        <GridItem order={[1, 1, 1, 1, 1]} colSpan={[5, 5, 2, 2, 2]} rowSpan={[1, 1, 1, 1, 1]}>
          <LineChart />
        </GridItem>
        <GridItem order={[2, 2, 2, 2, 2]} colSpan={[5, 5, 2, 2, 2]} rowSpan={[1, 1, 1, 1, 1]}>
          <BarChart />
        </GridItem>
        <GridItem order={[5, 5, 5, 3, 3]} colSpan={[5, 5, 4, 1, 1]} rowSpan={[1, 1, 1, 3, 3]}>
          <div>
            <Text className="text-item" color={'#000'} fontSize="20px" fontWeight={700} lineHeight="28px">
              Circulating Supply
            </Text>
            <CirculatingSupply />
          </div>
        </GridItem>
        <GridItem order={[3, 3, 3, 4, 4]} colSpan={[5, 5, 4, 2, 2]} rowSpan={1}>
          <Text className="text-item" color={'#000'} fontSize="20px" fontWeight={700}>
            Current Price
          </Text>
          <Box
            className="gsap-item"
            mt="10px"
            display={'flex'}
            justifyContent="space-between"
            padding="0px 40px 0px 40px"
          >
            <Text color={'#909090'} fontSize="12px" fontWeight={400}>
              Exchange
            </Text>
            <Text color={'#909090'} fontSize="12px" fontWeight={400}>
              Current Price
            </Text>
            <Text color={'#909090'} fontSize="12px" fontWeight={400}>
              Growth
            </Text>
          </Box>
          <CurrentPrice />
        </GridItem>
        <GridItem order={[4, 4, 4, 5, 5]} colSpan={[5, 5, 4, 2, 2]} rowSpan={1}>
          <Text
            className="text-item"
            color={'#000'}
            fontSize="20px"
            fontWeight={700}
            mt={['unset', 'unset', '30px', '30px', '30px']}
          >
            Statistics
          </Text>
          <Statistics />
        </GridItem>
        <GridItem order={[6, 6, 6, 6, 6]} colSpan={[5, 5, 4, 4, 4]} rowSpan={[1, 1, 1, 2, 2]} mb={4}>
          <Text className="text-item" color={'#000'} fontSize="20px" fontWeight={700}>
            Total Volume
          </Text>
          <TotalVolume />
        </GridItem>
        <GridItem
          order={[7, 7, 7, 7, 7]}
          colSpan={[5, 5, 4, 1, 1]}
          rowSpan={1}
          mt={['unset', 'unset', 'unset', '32px', '32px']}
        >
          <Flex flexDirection="column" height="100%" justifyContent="space-between">
            <Box>
              <Text className="text-item" color={'#000'} fontSize="20px" fontWeight={700} mb={2}>
                Fear & Greed
              </Text>
              <SpeedoMeter />
            </Box>
            <Box mt={4} pb={'40px'}>
              <Text className="text-item" color={'#000'} fontSize="20px" fontWeight={700} mb={2}>
                Pressure
              </Text>
              <PressureInfo initialBuyingPressure={buyingPressure} initialSellingPressure={sellingPressure} />
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
}

export default Content;
