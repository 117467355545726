import React, { useState, useEffect } from 'react';
import { Button, Row } from 'antd';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import Cross from '../../assets/images/svgs/cross.svg';
import AppModal from './AppModal';
import InputField from './InputField';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (e: boolean) => void;
  onSuccess: () => void;
}

const TokenAddressModal: React.FC<IProps> = ({ isShowModal, setIsShowModal, onSuccess }) => {
  const [tokenAddress, setTokenAddress] = useState('');
  const toast = useToast();

  const handleInputChange = (value: string) => {
    setTokenAddress(value);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.patch(
        'https://dev.api.covey.academy/api/user/patch-token-address/',
        { token_address: tokenAddress },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Token address updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onSuccess();
        setIsShowModal(false);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update token address',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <AppModal open={isShowModal} onCancel={() => setIsShowModal(false)} onClose={() => setIsShowModal(false)}>
      <Row
        style={{
          width: '401px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch' }}>
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.88)',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            Update Token Address
          </span>
          <div style={{ cursor: 'pointer' }} onClick={() => setIsShowModal(false)}>
            <img src={Cross} alt="Close" />
          </div>
        </Row>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
          <InputField
            label="Token Address"
            placeholder="Enter token address"
            value={tokenAddress}
            onChange={handleInputChange}
          />
        </div>
        <Button
          onClick={handleSaveChanges}
          style={{
            display: 'flex',
            padding: '0px 16px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '6px',
            border: '1px solid #2D8DFB',
            background: '#2D8DFB',
            color: '#FFF',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
          }}
        >
          Save Changes
        </Button>
      </Row>
    </AppModal>
  );
};

export default TokenAddressModal;
