import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadingStore, useLoginStore } from '../../../../store';
import { useForm } from 'react-hook-form';
import { RegisterUserDataType, RegisterUserDataTypePatch } from '../../../../interfaces';
import { CrossIcon } from '../../../../Icons/CrossIcon';
import { Select } from 'antd';
import { state } from '@antv/g2plot/lib/adaptor/common';

function TokenAddressForm() {
  const toast = useToast();
  const navigate = useNavigate();
  const stepNumber = loadingStore((state: any) => state.stepNumber);
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);
  const userData = useLoginStore((state: any) => state.userData);
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RegisterUserDataTypePatch>({
    defaultValues: {
      token_address: '',
    },
  });
  useEffect(() => {
    fetchNetworks();
  }, []);

  const fetchNetworks = async () => {
    try {
      const response = await axios.get('https://dev.api.covey.academy/api/user/blockchain-networks/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setNetworks(response.data);
    } catch (error) {
      console.error('Error fetching networks:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch blockchain networks',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleNetworkSelect = (value: string) => {
    setSelectedNetwork(value);
  };

  const handleFormSubmit = async (data: RegisterUserDataTypePatch) => {
    if (!selectedNetwork) {
      toast({
        title: 'Error',
        description: 'Please select a blockchain network',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.patch(
        'https://dev.api.covey.academy/api/user/account/',
        {
          blockchain_network: selectedNetwork,
          token_address: data.token_address,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      setStepNumber(4);
      toast({
        title: 'Success',
        description: 'Blockchain network and token address successfully updated',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating user data:', error);
      toast({
        title: 'Error',
        description: 'Failed to update user data',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // const handleFormSubmit = async (data: RegisterUserDataTypePatch) => {
  //   axios
  //     .patch('http://localhost:8000/api/user/account/', data, {
  //       headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setStepNumber(4);
  //         toast({
  //           title: 'Success',
  //           description: 'Token address successfully added',
  //           status: 'success',
  //           duration: 9000,
  //           isClosable: true,
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       toast({
  //         title: 'Error',
  //         description: 'Something went wrong while adding token address',
  //         status: 'error',
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     });
  // };

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center" height="inherit">
      <Text
        color="var(--Text-Primary-default, #000)"
        textAlign="center"
        fontSize="30px"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="38px"
        marginBottom="24px"
      >
        Before we start lets fill in some data!
      </Text>
      <Box
        borderRadius="20px"
        border="1px solid var(--colorSplit, rgba(0, 0, 0, 0.06))"
        background="var(--Cascader-colorWhite, #FFF)"
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)"
        display="flex"
        width="401px"
        padding="24px"
        flexDirection="column"
      >
        <Text
          color="rgba(0, 0, 0, 0.88)"
          textAlign="center"
          fontFamily="Poppins"
          fontSize="24px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="24px"
          marginTop="24px"
          marginBottom="24px"
        >
          Select your blockchain network
        </Text>
        <Select
          style={{ width: '100%', marginBottom: '24px', color: '#909090' }}
          placeholder="Select a blockchain network"
          onChange={handleNetworkSelect}
          value={selectedNetwork}
          options={networks.map((network: any) => ({
            value: network.id.toString(),
            label: network.network_name,
          }))}
        />

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormControl>
            <FormLabel color="#000" fontSize="12px" fontWeight={600}>
              What is your token address?
            </FormLabel>
            <Input
              id="token_address"
              {...register('token_address', {
                required: 'Token address is required',
              })}
              placeholder="Enter your token address"
              backgroundColor="#FFF"
              color="#909090"
              border="1px solid rgba(0, 0, 0, 0.15)"
              borderRadius="8px"
            />
          </FormControl>

          <Button
            background="#4096FF"
            color="white"
            _hover={{
              bg: '#494949',
              color: 'white',
            }}
            _active={{
              bg: '#4096FF',
              color: 'white',
            }}
            textTransform="capitalize"
            fontSize="14px"
            fontWeight={500}
            fontStyle="normal"
            width="100%"
            transition="0.2s all"
            mt="24px"
            h="46px"
            type="submit"
          >
            Next
          </Button>
        </form>
      </Box>
    </Box>
  );
}
export default TokenAddressForm;
